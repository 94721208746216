import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import JSZip from 'jszip';

import * as Session from './session';

const Utils = {
  /**
   * Flat Map 根据相应的字断
   * @param arr 源数组
   * @param step 层级
   * @param clear 是否删除原层级数据
   * @returns {[]|*[]}
   */
  flatMap(arr, step = 'children', clear = false) {
    const flatArr = [];
    const iterate = function(item) {
      flatArr.push(item);
      if(item[step]) {
        item[step].forEach(v => {
          iterate(v);
        });
        if(clear) {
          delete item[step];
        }
      }
    };
    if(!arr || !(arr instanceof Array)) {
      return [];
    } else {
      arr.forEach(iterate);
      return flatArr;
    }
  },

  /**
   * unFlat Map 根据相应的字断
   * @param items 扁平数组
   * @param key 父类id key
   * @param parentId 父类id key
   * @param child 子类 key
   * @returns {[]}
   */
  unFlatMap(items, key = 'id', parentId = 'parentId', child = 'children') {
    const result = [];   // 存放结果集
    const itemMap = {};  //
    for(const item of items) {
      const id = item[key];
      const pid = item[parentId];

      if(!itemMap[id]) {
        itemMap[id] = { [child]: [] };
      }

      itemMap[id] = { ...item, [child]: itemMap[id][child] };

      const treeItem =  itemMap[id];

      if(pid === 0) {
        result.push(treeItem);
      } else {
        if(!itemMap[pid]) {
          itemMap[pid] = { [child]: [] };
        }
        itemMap[pid][child].push(treeItem);
      }
    }
    return result;
  },

  params2json(params = '', slice = '&') {
    return params.split(slice).reduce((acc, item) => {
      let arr = item.split('=');
      return item ? { ...acc, ...{ [arr[0]]: arr[1] }} : acc;
    }, {});
  },

  handlerOptions(option, textKey, valueKey) {
    if(!option) throw new Error('option can not be undifend or null');
    const optionProps = {};
    if(typeof option === 'object') {
      optionProps.disabled = option.disabled || false;
      option = {
        [textKey]: option[textKey],
        [valueKey]: option[valueKey]
      };
    } else {
      option = { [textKey]: option, [valueKey]: option };
    }
    return { option, optionProps };
  },
  getFileExt(fileName = '') {
    const index = fileName.lastIndexOf('.');
    const regExp = new RegExp('^[A-Za-z0-9]+$');
    if(index > 0 && regExp.test(fileName.slice(index + 1))) {
      fileName = fileName.toLowerCase();
      return fileName.substring(index + 1);
    }
    return '';
  },
  // 导出文件
  exportFile(url = '', name = '') {
    if(!url) return;
    return FileSaver.saveAs(url, `${`${name}`.trim()}.${Utils.getFileExt(url)}`);
  },

  /**
   * 打包下载资源
   * @param {*} zipName 压缩包名
   * @param {*} files 文件 { path： 路径, url: 文件数据 }
   */
  async exportZip(zipName, files = [], cb) {
    let zip = new JSZip();
    for(let i = 0; i < files.length; i++) {
      const file = files[i];
      const data = await fetch(file.url).then(res => res.arrayBuffer());
      zip.file(file.path, data);
    }
    const zipFile = await zip.generateAsync({ type: 'blob' });
    cb && cb();
    FileSaver.saveAs(zipFile, zipName + '.zip');

  },
  isNotEmpty(value) {
    switch (typeof value) {
      case 'undefined': {
        return false;
      }

      case 'string': {
        return value.length !== 0;
      }

      case 'object': {
        if(Array.isArray(value)) {
          return value.length !== 0;
        } else if(value === null) {
          return false;
        } else {
          return Object.keys(value).length !== 0;
        }
      }

      default: {
        return true;
      }
    }
  },

  //保留搜索页
  checkSearchOptions(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey));
    } else {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    }
  },

  //保留搜索页
  /**
   * 传了searchOptions就会存储，不传就是读取
   * @param searchOptions
   * @returns {any|void}
   */
  checkSearchOptions1(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(searchOptions) {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    } else if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey || '{}'));
    }
  },

  searchAssign(origin = {}, values = {}, ...params) {
    let tempValue = Object.assign({}, values);
    if(params && params.length) {
      params.forEach((item) => {
        Object.assign(tempValue, item);
      });
    }
    if(Object.keys(tempValue).length) {
      for(let key in tempValue) {
        if(this.isNotEmpty(tempValue[key]) && this.isNotEmpty(String(tempValue[key]))) {
          origin[key] = tempValue[key];
        } else {
          delete origin[key];
        }
      }
    } else {
      for(let key in origin) {
        if(!this.isNotEmpty(origin[key])) {
          delete origin[key];
        }
      }
    }
    return origin;
  },

  /**
   * 先过滤对象中的空属性，再合并，使用方法类似于Object.assign，不对第一个参数做修改，而是将结果作为新的对象返回
   * @param args
   * @returns {{}}
   */
  filterMerge(...args) {
    return Object.assign.apply(null, args.map((object) => {
      return Object.keys(object).reduce((obj, key) => {
        const value = object[key];
        if(Utils.isNotEmpty(value) && value !== 'undefined') {
          obj[key] = value;
        }
        return obj;
      }, {});
    }));
  },

  json2params(json, slice = '&') {
    return Object.keys(json).reduce((acc, item) => {
      return String(acc) + item + '=' + json[item] + slice;
    }, '').slice(0, -1);
  },

  formatQuery2QueryStr(query = {}) {
    let queryStr = '';
    const filteredQuery = Utils.filterMerge(query);
    if(Utils.isNotEmpty(filteredQuery)) {
      queryStr = '?' + Utils.json2params(filteredQuery);
    }
    return queryStr;
  },

  /**
   * 链接拼参数
   * @param url 可以是带query的也可以是不带的
   * @param params 拼的参数对象
   * @returns {string}
   */
  padQuery(url = '', params = {}) {
    const [pathname, queryStr] = url.split('?');
    let tempQuery = Utils.filterMerge(Object.assign({}, Utils.params2json(queryStr), Utils.filterMerge(Object.assign({}, params))));
    let searchQuery = Utils.formatQuery2QueryStr(tempQuery);
    return `${pathname}${searchQuery}`;
  },
  // 权限管理处理数据信息
  handleDataInfo(data = [], type = 1) {
    let list = [];
    if(type === 1) {
      data.forEach(item => {
        const infoFirst = {};
        infoFirst.title = item.name;
        infoFirst.key = item.id;
        if(item.childList && item.childList.length > 0) {
          infoFirst.children = [];
          item.childList.forEach(v => {
            const infoTwo = {};
            infoTwo.title = v.name;
            infoTwo.key = v.id;
            infoFirst.children.push(infoTwo);
          });
        }
        list.push(infoFirst);
      });
    } else {
      data.forEach(item => {
        list.push(item.id);
        if(item.childList && item.childList.length > 0) {
          item.childList.forEach(v => {
            list.push(v.id);
          });
        }
      });
    }
    return list;
  },
  getDate(time) {
    if(time && !(+time > 0)) {
      let [tDate, tTime] = time.split(' ');
      tDate = tDate.replace(/[^\d]/g, '/');
      time = tTime ? `${tDate} ${tTime}` : `${tDate}`;
    }
    const d = time ? new Date(time) : new Date();
    const M = d.getMonth() + 1;
    const D = d.getDate();
    const h = d.getHours();
    const m = d.getMinutes();
    const s = d.getSeconds();
    return {
      Y: d.getFullYear(),
      M: M < 10 ? '0' + M : M,
      W: d.getDay(), //周几
      D: D < 10 ? '0' + D : D,
      h: h < 10 ? '0' + h : h,
      min: m < 10 ? '0' + m : m,
      s: s < 10 ? '0' + s : s,
      ms: d.getMilliseconds()
    };
  },
  getSizeByByte(size) {
    if(typeof size !== 'number') {
      size = Number(size);
      // throw Error("Argument Must Be A Number")
    }
    const KBUNIT = 'KB', MBUNIT = 'MB', UNITSIZE = 1024;
    let kb = size / UNITSIZE,
      mb = size / (UNITSIZE * UNITSIZE);
    return mb > 0.01
      ? parseFloat(mb).toFixed(2) + MBUNIT
      : parseFloat(kb).toFixed(2) + KBUNIT;
    // return parseFloat(mb).toFixed(2) + MBUNIT;
  },
  /**
   * 三位数内的阿拉伯数字转换
   * @param num
   * @returns {string}
   */
  numberConvertToUppercase(num) {
    num = Number(num);
    let upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
    let length = String(num).length;
    if(length === 1) {
      return upperCaseNumber[num];
    } else if(length === 2) {
      if(num === 10) {
        return upperCaseNumber[num];
      } else if(num > 10 && num < 20) {
        return '十' + upperCaseNumber[String(num).charAt(1)];
      } else {
        return upperCaseNumber[String(num).charAt(0)] + '十' + upperCaseNumber[String(num).charAt(1)].replace('零', '');
      }
    }
  },

  // 将数字转换为带千分符的金额格式
  formatCurrency(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  // 将数字转换为中文大写
  convertToChinese(num) {
    const units = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿'];
    const digits = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];

    let integerPart = Math.floor(num);
    let result = '';
    let unitIndex = 0;

    while(integerPart > 0) {
      const digit = integerPart % 10;
      if(digit > 0) {
        result = digits[digit] + units[unitIndex] + result;
      }
      integerPart = Math.floor(integerPart / 10);
      unitIndex++;
    }

    return result || digits[0]; // 返回零的情况
  },

  //防抖
  debounce(func, delay = 100) {
    let timer = null;
    return (...args) => {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  },

  //节流throttle代码：
  throttle(fn, delay = 500) {
    let canRun = true;
    return (...args) => {
      // 在函数开头判断标记是否为true，不为true则return
      if(!canRun) return;
      canRun = false;
      fn(...args);
      setTimeout(() => {
        canRun = true;
      }, delay);
    };
  },
  checkSupportWebP() {
    return true;
    // (
    //   document
    //     .createElement('canvas')
    //     .toDataURL('image/webp')
    //     .indexOf('data:image/webp') === 0
    // );
  },
  /**
   * oss图片
   * https://support.huaweicloud.com/usermanual-obs/obs_01_0430.html
   * https://help.aliyun.com/document_detail/50039.html?spm=5176.doc31848.6.750.BrFGlG
   * @param {string} src
   * @param {number} ratio 图片缩放比例 `(0-1]` 0不处理缩放
   * @param {object} effect 图片处理效果例如 `{resize:'w_100,h_100'}`
   * @returns {string}
   */
  getImg(src, ratio = 0.6, effect = {}) {
    const handleOssImg = (url, ratio, effects) => {
      const TYPE = 'image',
        EFFECTSKEY = 'x-oss-process';
      let baseUrl = url;
      let queryStr = '';
      let query = {};
      let queryIndex = url.indexOf('?');
      let effectsStr = '';
      if(queryIndex > -1) {
        baseUrl = url.slice(0, queryIndex);
        queryStr = url.slice(queryIndex + 1);
      }
      if(queryStr) {
        query = Utils.params2json(queryStr);
      }
      if(query[EFFECTSKEY]) {
        effectsStr = query[EFFECTSKEY];
      }
      const str2actions = (actionsStr = effectsStr) => {
        let actionsArr = actionsStr.split('/');
        let actions = {};
        if(actionsArr.length > 1) {
          actionsArr.slice(1).forEach((item = '') => {
            let keyIndex = item.indexOf(',');
            let key = item.slice(0, keyIndex),
              value = item.slice(keyIndex + 1);
            actions[key] = value;
          });
        }
        return actions;
      };
      const actions2str = (actions = {}) => {
        let str = '';
        for(let key in actions) {
          str += `/${key},${actions[key]}`;
        }
        return str;
      };

      if(ratio > 0) {
        ratio = ratio >= 1 ? 1 : ratio;
        Object.assign(effects, {
          resize: effects.resize || `p_${Math.round(ratio * 100)}`
        });
      }
      // webp
      if(Utils.checkSupportWebP()) {
        Object.assign(effects, {
          format: 'webp'
        });
      }
      if(Object.keys(effects).length) {
        let resultActions = `${TYPE}${actions2str(Object.assign({}, str2actions(), effects))}`;
        if(effectsStr) {
          let reg = new RegExp(effectsStr, 'g');
          return url.replace(reg, resultActions);
        } else {
          return `${baseUrl}?${Utils.json2params(Object.assign({}, query, {
            [EFFECTSKEY]: resultActions
          }))}`;
        }
      } else {
        return url;
      }
    };
      // 华为云gif => webp 异常
    if(src && src.indexOf('/oss/') > -1 && (!/\.(gif)$/.test(src))) {
      return handleOssImg(src, ratio, effect);
    } else {
      return src;
    }
  },

  //手机号脱敏
  desensitizePhone(phoneNumber) {
    return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1xxxx$2');
  },

  /** 深拷贝 */
  deepClone(data) {
    return JSON.parse(JSON.stringify(data));
  },

  //深拷贝 包含function，data等对象
  deepCloneObj(obj) {
    if(obj === null || typeof obj !== 'object') {
      return obj;
    }

    if(obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if(obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = Utils.deepCloneObj(item);
        return arr;
      }, []);
    }

    if(obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = Utils.deepCloneObj(obj[key]);
        return newObj;
      }, {});
    }
  },
  formatNumber3(num, fixedLength = 2, isYuan = false) {
    if(Number.isNaN(+num)) {
      return num;
    }
    const isNegative = num < 0;
    let value = Math.abs(num) || 0;
    if(value >= 1e4 && value < 1e8) {
      value = `${isNegative ? '-' : ''}${(value / 1e4).toFixed(fixedLength)}万`;
    } else if(value >= 1e8) {
      value = `${isNegative ? '-' : ''}${(value / 1e8).toFixed(fixedLength)}亿`;
    } else {
      if(isYuan) {
        value = num + '元';
      } else {
        value = num;
      }
    }
    return value;
  },
  //校验是否有数字人权限
  checkHasAiPermission(item = {}) {
    // let data = {
    //   ...item
    // };
    // let endTime =  new Date(item.endTime || 0).getTime();
    // let startTime =  new Date(item.startTime || 0).getTime();
    // let nowTime = new Date().getTime();
    // if(item?.code === 'knowledgebase') {
    //   data.status = 1;
    //   data.hiddenTime = true;
    // } else {
    // // 0 未设置时间
    // // 1 正常使用
    // // -1 过期
    // // -2 未到使用时间
    //   data.status = !item.startTime ? 0 : (endTime > nowTime && nowTime > startTime) ? 1 : nowTime > endTime ? -1 : -2;
    //   if(data.status === 1 && (endTime - nowTime) > (1000 * 60 * 60 * 24 * 7)) {
    //     data.hiddenTime = true;
    //   }
    // }
    // return data;
    return {
      ...item,
      status: 1,
      hiddenTime: true
    };
  },
  formatChatTime(time) {
    if(!time) return '';
    let now = +new Date(),
      createTime = +new Date(time);
    const nowHour = new Date().getHours();
    const s = Math.floor((now - createTime) / 1000),
      m = Math.floor(s / 60),
      h = Math.floor(m / 60);
      // d = Math.floor(h / 24);
    if(m < 1) {
      return `刚刚`;
    } else if(h < 1) {
      return `${m}分钟前`;
    } else if(h < 24 && (nowHour > h)) {
      return dayjs(time).format('HH:mm');
    } else if(h < 48) {
      return `昨天 ${dayjs(time).format('HH:mm')}`;
    } else {
      return dayjs(time).format('MM/DD');
    }
  },
  /**
   * 文件名长度超出时，中间用... 如： `海底两万里解...pdf`
   * @param {string} text 文件名，如：`海底两万里解析大全.pdf`
   * @param {string} ext 后缀，如：`pdf`
   * @param {number} maxLen 最大长度(中文按2个字符算)
   * @returns fileName
   */
  getShortFileName(text = '', ext, maxLen = 20) {
    ext = ext.replace(/^\./g, '');
    text = text.replace(new RegExp(`.${ext}$`, 'i'), '');
    // 判断字符是不是中文、标点
    const reg =  /[a-zA-Z0-9,.!@#$%^&*()\+-=\[\]\{}\|]/;
    const textMaxLen = maxLen - ext.length - 1;
    const textMaxLen1 = maxLen - ext.length - 4;
    let len = 0;
    let arr = [];
    // 遍历字符串
    for(let i = 0; i <= text.length; i++) {
      if(len > textMaxLen) {
        // textMaxLen
        let j = i;
        let cLen = len;
        for(; j > 0; j--) {
          cLen -= (arr[j] || 0);
          if(cLen <= textMaxLen1) {
            break;
          }
        }
        const shortText = text.substring(0, j).trim() + '....' + ext;
        return shortText;
      }
      // 超过了最大长度
      if(i === text.length) break;
      // 如果是中文
      if(!reg.test(text[i])) {
        // 中文字符长度为2
        len += 2;
        arr.push(2);
      } else {
        len += 1;
        arr.push(1);
      }
    }
    return text + '.' + ext;
  },

  //判断是否可用url
  isValidUrl(url) {
    const regex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    if(!regex.test(url)) {
      return false;
    }
    // 使用URL对象进行验证
    try {
      new URL(url);
      return true;
    } catch(e) {
      return false;
    }
  }
};

export default Utils;

