import { Input as _Input, Button } from 'antd';
import { useRef } from 'react';
import classNames from 'classnames';

import './index.scss';

function CustomSearch(props) {
  const { icon, className, onSearch, direct = '', ...restProps } = props;

  const inputRef = useRef(null);

  function onClickSearch(e) {
    e.stopPropagation();
    const value = inputRef.current?.input?.value;
    onSearch && onSearch(value);
  }
  function onPressEnter(e) {
    const value = e.target.value;
    onSearch && onSearch(value);
  }

  return (
    <_Input
      ref={inputRef}
      className={classNames('CustomSearchInput_fzOffCH', className)}
      allowClear
      {
      ...({
        [direct === 'left' ? 'prefix' : 'suffix']: <Button
          className="suffix_fzOffCH"
          type="text"
          size="small"
          icon={icon || <span className="ic ic-search" />}
          onClick={onClickSearch}
        />
      })
      }
      onPressEnter={onPressEnter}
      {...restProps}
    />
  );
}

// 左icon 右按钮
function CustomSearch2(props) {
  const { icon, className, onSearch, btnText = '搜索', loading = true, ...restProps } = props;

  const inputRef = useRef(null);

  function onClickSearch(e) {
    e.stopPropagation();
    const value = inputRef.current?.input?.value;
    onSearch && onSearch(value);
  }
  function onPressEnter(e) {
    const value = e.target.value;
    onSearch && onSearch(value);
  }

  return (
    <_Input
      ref={inputRef}
      className={classNames('CustomSearchInput2_fzOffCH', className)}
      allowClear
      prefix={<span className="ic ic-search" />}
      suffix={<Button
        className="suffix_fzOffCH2"
        type="primary"
        size="small"
        onClick={onClickSearch}
        loading={loading}
      >{btnText}</Button>}
      onPressEnter={onPressEnter}
      {...restProps}
    />
  );
}

/** @type {typeof _Input & {CustomSearch: typeof CustomSearch,CustomSearch2: typeof CustomSearch2}} */
const Input = Object.assign(_Input, {
  CustomSearch,
  CustomSearch2
});

export default Input;
