/**
 * Date    : 2021/11/19
 * Author  : WeiLin
 * Declare : index
 */
import { message } from 'antd';

/** @deprecated 请使用 `import { message } from '@/components/AntD'` */
const Message = message;

export default Message;
