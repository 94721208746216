import { message } from 'antd';
import './index.scss';

message.warn = message.warning;
// 初始化加载
message.config({
  top: 30,
  duration: 2,
  maxCount: 1
});

export default message;
