import { Table as _Table } from 'antd';

/**
 * wtdc Pagination组件数据转换成antd
 * @param {*} data 旧的翻页数据
 * @param {*} defaultFirstPage 翻页的初始页码
 * @returns pagination
 */
function transferPaginationInfo(data, defaultFirstPage = 0) {
  return {
    defaultFirstPage,
    current: data.currentPage + (1 - defaultFirstPage),
    pageSize: data.numPerPage,
    total: data.totalCount,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: () => `共${data.pageCount || 0}页${
      data.totalCount || 0
    }条`
  };
}
/**
 * wtdc翻页转换成antd
 * @param {*} data 旧的翻页数据
 * @param {*} defaultFirstPage 翻页的初始页码
 * @returns
 */
function transferPagination(data, defaultFirstPage = 0) {
  return {
    current: data.currentPage + (1 - defaultFirstPage),
    pageSize: data.numPerPage
  };
}
/**
 * antd翻页数据中提取wtdc翻页
 * @param {*} pagination antd pagination
 * @returns
 */
function transferPaginationBack(pagination, defaultFirstPage = 0) {
  return {
    currentPage: pagination.current - (1 - defaultFirstPage),
    numPerPage: pagination.pageSize
  };
}

export {
  transferPagination,
  transferPaginationBack
};

const Table = Object.assign(_Table, {
  transferPagination,
  transferPaginationInfo,
  transferPaginationBack
});

export default Table;
