import user from './user';
import projectSetting from './project-setting';
import common from './common';
import activity from './activity';
import studio from './studio';
import dialogue from './dialogue';
import editor from './editor';

export default [
  user,
  common,
  projectSetting,
  activity,
  studio,
  dialogue,
  editor
];
