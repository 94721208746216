import React from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

// eslint-disable-next-line
import Login from './login';
import THEME_CONFIG from '../assets/theme/theme';
// import NewLogin from './newLogin';

import './App.scss';

// Modal.confirm 无法移除 :where 选择器
// https://ant-design.antgroup.com/components/config-provider-cn#configproviderconfig
ConfigProvider.config({
  // 5.13.0+
  holderRender: (children) => (
    <ConfigProvider
      // prefixCls="ant"
      // iconPrefixCls="anticon"
      theme={THEME_CONFIG.theme}
    >
      {children}
    </ConfigProvider>
  )
});

export default class App extends React.PureComponent {

  componentDidMount() {
    const ismac = /macintosh|mac os x/i.test(navigator.userAgent);
    if(ismac) {
      document.body.setAttribute('ismac', '1');
    }
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    return (
      <ConfigProvider {...THEME_CONFIG}>
        <Switch>
          { this.props.children || <Login /> }
          {/* { this.props.children || <NewLogin /> } */}
        </Switch>
      </ConfigProvider>
    );
  }
}
