/**
 * Date    : 2021/11/18
 * Author  : WeiLin
 * Declare : index
 */

import React, { useImperativeHandle } from 'react';

import { Form as _Form } from '@/components/AntD';

import { COMMON_FIELDS } from './fields';

import './index.scss';

// Field 类型
export const FieldType = Object.keys(COMMON_FIELDS);

function addValidator(itemProps, validator) {
  const rules = itemProps.rules || [];
  if(validator && rules?.length > 0) {
    // 有规则时，将 validator 添加到 `reqired:true` 的规则下
    rules.some(rule => {
      if(rule.reqired) {
        rule.validator = validator;
        return true;
      }
      return false;
    });
    itemProps.rules = rules;
  }
}

const _renderField = (field) => {
  const { type = 'input', fieldProps, ...itemProps } = field;
  // 渲染 FormItem 类型, fieldProps 参数将作为props传递给基础组件
  if(FieldType.includes(type)) {
    const validator = COMMON_FIELDS[type].validator;
    validator && addValidator(itemProps, validator);
    return (
      <_Form.Item {...itemProps}>
        { COMMON_FIELDS[type]({ ...fieldProps }) }
      </_Form.Item>
    );
  } else {
    console.error(`${type} does not exist`);
  }
};

function renderDependencyField({ dependencyValue, ...itemProps }) {
  return (
    <_Form.Item noStyle shouldUpdate>
      {
        _renderField(itemProps)
      }
    </_Form.Item>
  );
}

function renderDefineField(props) {
  const { component: Component, fieldProps, ...itemProps } = props;
  // 自定义组件需考虑读写两种状态 proProps.mode | text
  // const componentProps = {
  //   onChange,
  //   ...itemProps
  // };
  const validator = Component.validator;
  validator && addValidator(itemProps, validator);
  return (
    <_Form.Item {...itemProps}>
      <Component />
    </_Form.Item>
  );
}

function renderField(fieldItem) {
  const { dependencyValue, component, ...itemProps } = fieldItem;
  // 依赖表单
  if(Array.isArray(dependencyValue)) {
    return renderDependencyField(fieldItem);
  }

  // 自定义表单
  if(component) {
    return renderDefineField(fieldItem);
  }
  // Form 表单
  return _renderField(itemProps);
}

/**
 * Form表单封装
 * @typedef {{type:string,label:string,name:string,rules:[],fieldProps:{placeholder:string}}&import('antd').FormItemProps} FieldType
 * @typedef {object} Props
 * @property {React.MutableRefObject} parentRef
 * @property {string} className
 * @property {FieldType[]} fields 参数`fieldProps`传给`Form.Item`的子组件，其余传给`Form.Item`
 * @param {React.ReactNode} children
 * @param {Props&import('antd').FormProps} props
 * @returns
 */
export default function FormRender(props) {

  const { parentRef, className, fields, children, ...restProps } = props;

  // form 实例
  const [form] = _Form.useForm();

  // getForm就是暴露给父组件的方法
  // 外部获取 parentRef = useRef();
  useImperativeHandle(parentRef, () => form);

  function renderForm(itemProps) {
    const { options } = itemProps;
    // 多类型
    if(Array.isArray(options)) {
      return (
        <_Form.Group {...itemProps}>
          {
            options.map(fieldItem => renderField(fieldItem))
          }
        </_Form.Group>
      );
    }
    return renderField(itemProps);
  }

  return (
    <_Form form={form} className={className} {...restProps}>
      {
        fields.map(fieldItem => {
          return (
            <React.Fragment key={fieldItem.name}>
              { renderForm(fieldItem) }
            </React.Fragment>
          );
        })
      }
      { children }
    </_Form>
  );
}

// Removed: propTypes and defaultProps for functions
// https://react.dev/blog/2024/04/25/react-19-upgrade-guide#removed-proptypes-and-defaultprops
FormRender.defaultProps = {
  parentRef: null,
  className: '',
  /**
   * 参数`fieldProps`传给`Form.Item`的子组件，其余传给`Form.Item`
   * @type {{type:string,label:string,name:string,rules:[],fieldProps:{placeholder:string}}[]}
   */
  fields: [], // fields extend ITEM_PROPS
  children: null
};

// fieldProps 基础组件 props
export const FormField = renderField;

// form
export const Form = _Form;

