import aicaptainapi from '@/api/aicaptainapi';
import aireviewapi from '@/api/aireviewapi';
import Immutable from 'seamless-immutable';

const namespace = 'editor';

export default {
  namespace,
  state: Immutable({
    fileList: [],
    uploadAuth: {},
    reviewExample: null
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    disabledUploadAuth: (state) => {
      return state.merge({
        uploadAuth: { ...state.uploadAuth, ...(state.uploadAuth?.isExperienceAccount ? { isUpload: 0 } : {}) }
      });
    }
  },
  actions: {
    setFileList: function(fileList) {
      return {
        type: 'editor/update',
        data: fileList,
        field: 'fileList'
      };
    },
    getReviewExampleList: () => {
      return {
        type: 'editor/update',
        field: 'reviewExample',
        promise: aicaptainapi.getExampleByCode({ code: 'ai-reviser-examplefile' })
      };
    },
    checkUploadAuth: function(payload) {
      return {
        type: 'editor/update',
        field: 'uploadAuth',
        promise: aireviewapi.getFileUploadAuth(payload)
      };
    },
    disabledUploadAuth: () => {
      return {
        type: 'editor/disabledUploadAuth',
        field: 'uploadAuth'
      };
    }
  }
};