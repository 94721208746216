import axiosServices from './request';

const DemandApi = axiosServices('demand/v1.0');

export default {
  // 首次登录欢迎语
  sendWelcome: DemandApi('post', 'market/welcome'),

  // 用户选择图书类别列表
  bookCategoryList: DemandApi('get', 'userBookMarketing/bookCategoryList'),

  // 用户删除历史营销记录
  deleteDemand: DemandApi('post', 'userBookMarketing/deleteDemand'),

  //用户查看图书营销进度
  demandProgress: DemandApi('get', 'userBookMarketing/demandProgress'),

  //  用户查看历史营销记录
  demandRecords: DemandApi('get', 'market/bookMarketingList'),

  // 用户发起图书营销需求
  launchDemand: DemandApi('post', 'userBookMarketing/launchDemand'),

  // 修改登录密码
  changePassword: DemandApi('post', 'userAccount/changePassword'),

  // 修改登录密码 post /userAccount/retrieve
  retrieve: DemandApi('post', 'userAccount/retrieve'),

  // 用户提交留资
  consult: DemandApi('post', 'userAccount/consult'),

  // 查看用户信息
  getUserInfo: DemandApi('get', 'userAccount/info'),

  // 用户账号密码登录
  login: DemandApi('post', 'userAccount/login'),

  /** 编辑端通过jwt换取登录信息 */
  loginByJwt: DemandApi('get', 'userAccount/loginByJwt'),

  // 用户账号密码登录
  logout: DemandApi('post', 'userAccount/logout'),

  // 忘记密码找回账号
  retrieveAccount: DemandApi('post', 'userAccount/retrieve'),

  // 修改账号关联信息
  updateAccount: DemandApi('post', 'userAccount/update'),

  // 修改账号关联信息
  exportFiles: DemandApi('post', 'userBookMarketing/exportFiles'),

  // 获取导出的模块交付物
  getExportedModuleFiles: DemandApi('get', 'userBookMarketing/getExportedModuleFiles'),

  //提交导出模块交付
  submitExportModuleFiles: DemandApi('post', 'userBookMarketing/submitExportModuleFiles'),

  //用户图书营销需求前置校验
  checkDemandName: DemandApi('post', 'userBookMarketing/checkDemand'),
  /** 渠道 */
  channelEntranceList: DemandApi('get', 'operatorEntrance/channelEntranceList'),
  /** 获取渠道用户 */
  getChannelUserList: DemandApi('get', 'mediaOperation/channelUser/getList'),

  // 首页图书翻页
  fetchMoreBookList: DemandApi('post', 'market/fetchMoreBookList'),
  // 首页没有想要的书翻页
  searchBookList: DemandApi('post', 'market/searchBookList'),
  /** 检查当前用户口是否有图书记录 */
  hasBookRecord: DemandApi('get', 'market/hasBookRecord'),
  /** 删除图书营销方案 */
  deleteById: DemandApi('get', 'market/deleteById'),

  // 营销方案-更新视频标题信息
  updateVideoBookInfo: DemandApi('post', 'market/updateVideoBookInfo'),
  // 营销方案-补充资料
  supplyResource: DemandApi('post', 'video/supplyResource'),

  // 数据洞察-销售总览
  getBookDataOverview: DemandApi('get', 'bookData/getBookDataOverview'),
  // 数据洞察-销售渠道分布
  getBookDataSaleTerminalDistribute: DemandApi('get', 'bookData/getBookDataSaleTerminalDistribute'),
  // 数据洞察-销售方式分布
  getBookDataSaleMethodDistribute: DemandApi('get', 'bookData/getBookDataSaleMethodDistribute'),
  // 数据洞察-数据趋势
  getBookDataTrend: DemandApi('get', 'bookData/getBookDataTrend'),
  // 数据洞察-数据明细
  getBookDataList: DemandApi('get', 'bookData/getBookDataList'),

  // 数据洞察详情-数据明细销售趋势
  getBookDataSaleTrend: DemandApi('get', 'bookData/getBookDataSaleTrend'),

  // 数据洞察详情-数据明细销售趋势导出
  exportBookDataSaleTrend: DemandApi('get', 'bookData/exportBookDataSaleTrend'),
  // 数据洞察详情-图书信息
  getBookInfo: DemandApi('get', 'bookData/getBookInfo'),

  /** 编辑端根据typeCode获取expertId */
  getExpertIdByTypeCode: DemandApi('get', 'userAccount/getExpertIdByTypeCode'),

  /** 获取视频生成状态 */
  queryByDemandIds: DemandApi('post', 'video/queryByDemandIds'),
  /** 重试 */
  reTry: DemandApi('get', 'video/reTry')
};
