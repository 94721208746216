import React, { useEffect, useMemo, useState } from 'react';
import anime from 'animejs';
import { useSetState } from 'ahooks';

import { Carousel } from '@/components/AntD';
import Image from '@/components/Image';

import './index.scss';
function PartThree(props) {

  const [currentSlide, setCurrentSlide] = useState(0);

  const { isInViewport, scrollY } = props;

  const [animationExecuted, setAnimationExecuted] = useSetState(false);

  useEffect(() => {
    const viewNode = document.querySelector('.voyage-part-three-title');
    const isInView = isInViewport(viewNode);
    if(animationExecuted || !isInView) return;
    setAnimationExecuted(true);
    let tl = anime.timeline({
      easing: 'easeOutExpo'
      // duration: 750
    });
    tl.add({
      targets: '.voyage-part-three-title .part-cicle',
      opacity: [0, 0.5],
      duration: 500,
      easing: 'easeInOutQuad'
    }).add({
      targets: '.voyage-part-three-title .part-title, .voyage-part-three-title .part-subtitle',
      translateX: [200, 0],
      opacity: [0, 1],
      delay: anime.stagger(200), // 子元素依次出现
      easing: 'easeInOutQuad'
    }).add({
      targets: '.part-three-content .slick-slider, .part-three-content .slick-thumb',
      opacity: [0, 1],
      duration: 750,
      easing: 'easeInOutExpo'
    }).add({
      targets: '.voyage-part-three-right .voyage-part-three-right-info .part-cicle',
      opacity: [0, 0.5],
      duration: 500,
      easing: 'easeInOutQuad'
    }).add({
      targets: document.querySelectorAll('.voyage-part-three-right .voyage-part-three-right-info .info-text'),
      opacity: [0, 1],
      delay: anime.stagger(300), // 每个元素之间的延迟
      easing: 'linear'
    }).add({
      targets: '.slide-report',
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 100 })
    }).add({
      targets: '.slide-report .slide-report-lable',
      translateX: [-270, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 100 }) // delay starts at 500ms then increase by 100ms for each elements.
    }, '-=1000').add({
      targets: '.voyage-part-three-right .part-three-right-chart',
      opacity: [0, 1]
    }, '-=1500');
  }, [scrollY]);

  const changeSlide = (nextSlide) => {
    setCurrentSlide(nextSlide);
    setTimeout(() => {
      let tl = anime.timeline({
        easing: 'easeOutExpo'
      });
      tl.add({
        targets: '.slide-report',
        opacity: [0, 1],
        delay: anime.stagger(100, { start: 100 })
      }).add({
        targets: '.slide-report .slide-report-lable',
        translateX: [-270, 0],
        opacity: [0, 1],
        delay: anime.stagger(100, { start: 100 }) // delay starts at 500ms then increase by 100ms for each elements.
      }, '-=1000').add({
        targets: '.voyage-part-three-right .part-three-right-chart',
        opacity: [0, 1]
      }, '-=1500');
    }, 0);
  };

  const slideProps = useMemo(() => {
    return {
      beforeChange: (current, nextSlide) => changeSlide(nextSlide),
      customPaging: (index) => {
        return (
          <Image className="slid-dot" src={DIGITAL_LIST[index].avatar} />
        );
      },
      dots: {
        className: 'slick-thumb'
      },
      fade: true,
      // autoplay: true,
      infinite: true,
      autoplaySpeed: 5000
    };
  }, [currentSlide]);

  const currentSlideValues = useMemo(() => {
    return DIGITAL_LIST[currentSlide];
  }, [currentSlide]);

  return (
    <div className="voyage-part voyage-part-three">
      <div className="voyage-part-three-title">
        <div className="part-title">
          <div className="part-cicle" />
          七大专家团队 三大核心服务内容
        </div>
        <div className="part-subtitle">
          <div className="mb30 gray-65">Expert Teams, Three Core Services</div>
          <div className="gray-42 part-subtitle-desc">七大专家团队通过对图书、读者、平台的深入分析，一站式输出专业策划方案，覆盖图书新媒体营销全链路，为出版行业提供更专业、更高效、更全面的营销方案。</div>
        </div>
      </div>
      <div className="part-three-content dis-flex">
        <div className="voyage-part-three-left">
          <Carousel {...slideProps}>
            {
              DIGITAL_LIST.map((item, index) => {
                return (
                  <div key={index} className="three-left-image-wrap">
                    <Image width="100%" src={item.robotAvatar} alt="" />
                  </div>
                );
              })
            }
          </Carousel>
          <div className="slide-report" key={currentSlide}>
            <div className="slide-report-lable lable-title">团队专长</div>
            {
              [...currentSlideValues.ability].map((lable, key) => {
                return (
                  <div key={key} className="slide-report-lable lable-subtitle">{lable}</div>
                );
              })
            }
          </div>
        </div>
        <div className="voyage-part-three-right">
          <div className="voyage-part-three-right-info">
            <div className="info-text part-three-title text-bold">{currentSlideValues.name}</div>
            <div className="part-color-dark part-three-subtitle info-text">{currentSlideValues.title}</div>
            <div className="part-subtitle info-text gray-42">{currentSlideValues.subtitle}</div>
          </div>
          <div className="part-three-right-chart">
            <Image width="100%" src={currentSlideValues.detailBg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

PartThree.defaultProps = {};

export default PartThree;

export const DIGITAL_LIST = [
  {
    avatar: 'https://oss.5rs.me/oss/uploadfe/png/4a5ccb6b30bb4250650e818af58ad1be.png',
    name: '营销矩阵数据分析专家团队',
    robotAvatar: 'https://oss.5rs.me/oss/uploadfe/png/8976d521dc4d548eb589b8f1d90638d7.png',
    detailBg: 'https://oss.5rs.me/oss/uploadfe/png/016a616220c683736e63f760dbdbf782.png',
    title: <span>多、快、智、全的<span className="text-bold">新媒体营销结案复盘</span></span>,
    subtitle: '从粉丝、内容、投流、运营优化4个方面，高效生成智能化、多维度的数据分析报告，时时刻刻把握营销方向。',
    ability: [
      '多维度的粉丝数据分析',
      <span key="2">快速高效的内容<br />数据分析</span>,
      '智能化的投流数据分析',
      '全方位的运营优化报告'
    ]
  },
  {
    avatar: 'https://oss.5rs.me/oss/uploadfe/png/fdac302e76576ab4feef08b16592332c.png',
    name: '电商平台文案创作专家团队',
    robotAvatar: 'https://oss.5rs.me/oss/uploadfe/png/1db1afe8c27a6489d77a5daff531db22.png',
    detailBg: 'https://oss.5rs.me/oss/uploadfe/png/cf9a6302b1d6b1733c03a82c6df5639b.png',
    title: <span>精准高效的<span className="text-bold">电商文案</span></span>,
    subtitle: '10年经验专业电商运营团队，借助动态大数据、大算力中心，通过出版行业垂类AI大模型BOOKSGPT深度赋能，根据电商平台用户兴趣点高效创作，打造具有吸引力、精准高效的爆款文案。',
    ability: [
      '产品标题',
      '产品副标题',
      '关键词',
      '编辑推荐提取',
      '内容简介提取',
      '作者介绍提取',
      '精彩书评生成',
      '目录提取',
      '精彩书摘提取'
    ]
  },
  {
    avatar: 'https://oss.5rs.me/oss/uploadfe/png/945b299a1ccd48df0992cbf0086107a9.png',
    name: '新媒体平台设计专家团队',
    robotAvatar: 'https://oss.5rs.me/oss/uploadfe/png/8e096da39e4d86afb95c40280096282d.png',
    detailBg: 'https://oss.5rs.me/oss/uploadfe/png/8cdd1f7eb47e7a31e3a247a0f778bb52.png',
    title: <span>强势吸睛的<span className="text-bold">创意视觉</span></span>,
    subtitle: '资深视觉设计专家团队，提供智能、专业、全面、准确的内容生产服务，帮您的图书高效输出营销爆款内容素材包，助力出版单位内容创作人员轻松拿捏新媒体内容创作挑战，随时随地打造爆款书。',
    ability: [
      '爆款立体封',
      '产品海报',
      '氛围感十足的产品图',
      '透明底产品图',
      '一目了然的产品详情页',
      '新媒体电商平台详情页',
      '戳中痛点的宣传文案',
      '推广banner直通车告位图',
      <span key="9">推广banner直通车推<br />广广告位图</span>,
      '主题明确的活动海报',
      '新颖独特的宣传视频'
    ]
  },
  {
    avatar: 'https://oss.5rs.me/oss/uploadfe/png/05b1ddcbd784dba6404e98befb6a9350.png',
    name: '新媒体电商直播专家团队',
    robotAvatar: 'https://oss.5rs.me/oss/uploadfe/png/615d842c74704ae3d5d248056b5eb199.png',
    detailBg: 'https://oss.5rs.me/oss/uploadfe/png/f7749db66b5ea0e356b90a66a69191c1.png',
    title: <span>场场爆单的<span className="text-bold">电商直播规划</span></span>,
    subtitle: '1亿+投流经验顶级投手，提供智能、精准、有爆点的直播内容及投流规划，打造专业化、标准化的电商直播策略，助力直播场场爆单、图书本本爆款。',
    ability: [
      '直播选品清单',
      <span key="2">重点明确的直播<br />手卡脚本</span>,
      '定制化的直播形式',
      '金牌主播话术',
      '爆款直播标题',
      '点击率爆棚的直播封面',
      '提神醒脑的直播音效包',
      '直播间精彩集锦',
      '直播间花式宠粉福利',
      '直播精准投流规划'
    ]
  },
  {
    avatar: 'https://oss.5rs.me/oss/uploadfe/png/184fca3f71b6ab9b894acd5d35fb1df0.png',
    name: '新媒体平台运营专家团队',
    robotAvatar: 'https://oss.5rs.me/oss/uploadfe/png/48426e198b28773d443bc78677bca03a.png',
    detailBg: 'https://oss.5rs.me/oss/uploadfe/png/a8578982c926049b68c17eaa218281b0.png',
    title: <span>精细化定制化的<span className="text-bold">新媒体平台运营</span></span>,
    subtitle: '100+头部账号操盘经验，专业新媒体平台运营团队，深度分析各平台属性，根据不同平台用户兴趣点高效创作，打造具有吸引力与情感共鸣的多类型爆款内容。',
    ability: [
      '图书亮点',
      '教科书级的带货话术',
      '高级抢眼的配图 ',
      '一看就种草的文案',
      '有理有据的书评文案',
      '原创细致的视频脚本',
      '高清高质的视频输出',
      '点击率爆棚的视频封面',
      '爆点满满的直播预热海报',
      '悬念十足的直播预热视频',
      '精彩纷呈的直播切片'
    ]
  },
  {
    avatar: 'https://oss.5rs.me/oss/uploadfe/png/4296f85e082976eb9a197371beb46603.png',
    name: '达人渠道专家团队',
    robotAvatar: 'https://oss.5rs.me/oss/uploadfe/png/227a8947087ee44e6b38bb34f2308e43.png',
    detailBg: 'https://oss.5rs.me/oss/uploadfe/png/2d1c9465c4b2d64f0bc0017134f31950.png',
    title: <span>品效合一的<span className="text-bold">媒介组合战略</span></span>,
    subtitle: '自建各平台矩阵账号200+个，聚集200+长期合作KOL/KOC，覆盖9大内容新媒体平台，目前聚焦童书与社科赛道。开拓更多有效的新媒体销售渠道，组成一体化的营销-推广转化链路，助力持续提升转化。',
    ability: [
      '达人库检索',
      '达人矩阵策略',
      '达人种草效果分析报告'
    ]
  },
  {
    avatar: 'https://oss.5rs.me/oss/uploadfe/png/a4e2018826ea847ccf4e0f9b8d675436.png',
    name: '新媒体图书营销策划专家团队',
    robotAvatar: 'https://oss.5rs.me/oss/uploadfe/png/eb4f8c97b094001b9ba6507ca1d545d1.png',
    detailBg: 'https://oss.5rs.me/oss/uploadfe/png/aab3578ef5caa9f597a3b402584fa6ed.png',
    title: <span>一站式专业的<span className="text-bold">新媒体营销策划方案</span></span>,
    subtitle: '资深运营团队精准定位用户需求，提供图书营销策略及营销节奏，一站式打造精细化、个性化的专业图书新媒体策划。',
    ability: [
      '目标图书概况',
      '竞品多维度分析报告',
      '目标人群画像',
      '营销阵地锁定',
      '目标人群偏好洞察',
      '媒介组合战略',
      '营销创意主题',
      '传播节奏与规划',
      '新媒体营销策划方案 '
    ]
  }

];