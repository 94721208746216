import axiosServices from './request';

const AiCaptain = axiosServices('/aicaptain/v1.0');

export default {
  // 获取员工列表
  listEmployee: AiCaptain('get', 'employee/listEmployee'),
  // 获取指令列表
  listAbility: AiCaptain('get', 'employee/listAbility'),
  // 任务消息列表
  listTaskMessageRecord: AiCaptain('get', 'messageRecord/listTaskMessageRecord'),
  // 任务记录列表
  listMessageRecord: AiCaptain('get', 'messageRecord/listMessageRecord'),
  // 反馈
  addFeedBack: AiCaptain('post', 'employee/addFeedBack'),
  // 发送欢迎语
  sendWelcome: AiCaptain('post', 'employee/sendWelcome'),
  getAbilityById: AiCaptain('get', 'employee/getAbilityById'),
  // 未读消息更新为已读
  setReadStatus: AiCaptain('get', 'messageRecord/setReadStatus'),
  // 全局搜索
  searchAll: AiCaptain('post', 'aiChat/searchAll'),
  // 获取用户最近使用任务
  getLatelyTask: AiCaptain('get', 'aiChat/getLatelyTask'),
  // 通过code获取指令
  getExampleByCode: AiCaptain('get', 'commonConfig/getByCode'),
  // 获取已经获得权限的列表
  getListEmployeeByAuthority: AiCaptain('get', 'employee/listEmployeeByAuthority'),
  // 员工点击
  employeeClick: AiCaptain('get', 'employee/employeeClick'),
  // 获取员工信息
  getEmp4Adviser: AiCaptain('get', 'employee/getEmp4Adviser'),
  //获取已经获得权限的列表
  listEmployeeByAuthority: AiCaptain('get', 'employee/listEmployeeByAuthority'),

  /** 检查url是否能打开 */
  checkUrlStatus: AiCaptain('get', 'commonApi/checkUrlStatus'),

  // 能力列表-能力置顶排序
  listAbilityByTop: AiCaptain('get', 'employee/listAbilityByTop'),

  // 发送欢迎语
  topicSendWelcome: AiCaptain('post', 'employee/topicSendWelcome'),

  // 查询推荐引导内容
  getSuggestContent: AiCaptain('get', 'suggest/getSuggestContent')
};
