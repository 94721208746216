/**
 *  Date    : 2021/11/18
 *  Author  : WeiLin
 *  Declare : 通用常量
 */

// 环境标识
export const ENV = {
  DEV: 'DEV',
  TEST: 'TEST',
  UAT: 'UAT',
  PRO: 'PRO'
};

export const TOKEN = 'TOKEN';

export const PORTAL_ID = {
  BREAD_CRUMB: 'BREAD_CRUMB',
  BOTTOM: 'PORTAL_BOTTOM'
};

export const EMPTY_PAGE_BEAN = {
  recordList: []
};
export const EMPTY_PAGE_BEAN_NEW = {
  list: []
};

//表图数据初始值
export const EMPTY_CHART_DATA = {
  values: [[]],
  axis: [],
  axisSort: []
};

export const NOOP = () => {};   // 无操作，常用于props缺省值
export const JUST_RETURN = _ => _;    // 直接返回
export const RETURN_EMPTY_OBJECT = () => ({});    // 返回空对象，常用于connect传递空的mapStateToProps
export const PREVENT_DEFAULT = event => event.preventDefault();
export const STOP_PROPAGATION = event => event.stopPropagation();

export const FILE_TYPE = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  EXCEL: 'EXCEL',
  PDF: 'PDF',
  WORD: 'WORD',
  PPT: 'PPT',
  ZIP: 'ZIP',
  TXT: 'TXT',
  ALBUM: 'ALBUM'
};

export const FILE_ICON = {
  PDF: 'PDF-icon',
  WORD: 'WORD-icon',
  EXCEL: 'Excel-icon',
  ALBUM: 'picture'
};

export const GET_FILE_TYPE = (file) => {
  const formatMap = FORMAT_MAP();
  let fileKey = '';
  if(Object.keys(formatMap).includes(file?.fileType)) {
    fileKey = file?.fileType;
  } else {
    fileKey = Object.keys(formatMap).find(key => formatMap[key].includes(file?.fileType || file?.ext));
  }
  return fileKey;
};

export const FORMAT_MAP = (convert = true) => ({
  AUDIO: convert ? ['mp3', 'wav', 'aac', 'wma', 'ape', 'amr'] : ['mp3'],
  VIDEO: convert
    ? ['mp4', 'mkv', 'mpg', 'avi', 'asx', 'mov', 'flv', 'rm', 'wmv', 'rmvb']
    : ['mp4'],
  ALBUM: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp'],
  PDF: ['pdf'],
  LYRIC: ['lrc'],
  EXCEL: ['csv', 'xls', 'xlsx', 'xlsm', 'xltm', 'xlam'],
  WORD: ['doc', 'docx'],
  PPT: ['ppt', 'pptx'],
  ZIP: ['zip', 'rar'],
  TXT: ['txt']
});

export const FILE_TYPES_FOR_UPLOAD = {
  PDF: 'PDF',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  ALBUM: 'ALBUM',
  LYRIC: 'LYRIC',
  EXCEL: 'EXCEL',
  ZIP: 'ZIP',
  'AUDIO_TEXT': 'AUDIO-TXT',
  TXT: 'TXT',
  WORD: 'WORD',
  DOCX: 'DOCX',
  DOC: 'DOC',
  PPT: 'PPT',
  EBOOK: 'EBOOK',
  WORD_PDF: 'WORDANDPDF',
  WORD_PDF_IMAGE: 'WORD_PDF_IMAGE',
  WORD_PDF_EXCEL_IMAGE: 'WORD_PDF_EXCEL_IMAGE',
  WORD_TXT: 'WORDANDTXT',
  WORD_PDF_TXT: 'ALLWORDTYPE',
  WORD_PDF_EXCEL: 'WORDPDFEXCEL',
  MEDIAS: 'MEDIAS',
  'PDF_TXT_PPT_WORD': 'PDF-TXT-PPT-WORD'
};

export const FILE_ACCEPT = (needConvert = true) => ({
  PDF: {
    accept: 'application/pdf',
    name: 'PDF',
    tip: ''
  },
  AUDIO: {
    accept: needConvert
      ? 'audio/mp3, audio/wav, audio/aac, audio/x-ms-wma, audio/x-monkeys-audio, audio/amr'
      : 'audio/mp3',
    name: '音频',
    tip: needConvert ? '上传的音频将自动转换为mp3格式。' : '音频文件支持MP3格式。'
  },
  VIDEO: {
    accept: needConvert
      ? 'video/mp4, video/x-matroska, video/mpeg, video/x-msvideo, video/quicktime, video/x-flv, application/vnd.rn-realmedia, video/x-ms-wmv, application/octet-stream'
      : 'video/mp4, video/x-matroska',
    name: '视频',
    tip: needConvert ? '上传的视频将自动转换为mp4格式。' : '视频文件支持MP4格式。'
  },
  ALBUM: {
    accept: 'image/png, image/gif, image/jpeg',
    name: '图片',
    tip: ''
  },
  LYRIC: {
    accept: '',
    name: '歌词',
    tip: '歌词文件支持LRC格式。'
  },
  EXCEL: {
    accept:
      'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'EXCEL表格',
    tip: '文件格式支持csv、xls、xlsx、xlsm、xltm、xlam格式'
  },
  ZIP: {
    accept: 'application/zip, application/x-rar-compressed',
    name: '压缩文件',
    tip: '压缩文件支持ZIP、RAR格式。'
  },
  'AUDIO-TXT': {
    accept: 'text/plain',
    name: '音频原文',
    tip: '音频原文文件支持TXT格式。'
  },
  WORD: {
    accept:
      'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'WORD文档',
    tip: '文件格式支持doc、docx格式'
  },
  PPT: {
    accept:
      'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
    name: 'PPT文档',
    tip: '文件格式支持ppt、pptx格式'
  },
  EBOOK: {
    accept: 'epub',
    name: '电子书',
    tip: '文件格式支持epub格式'
  },
  WORD_EXCEL_PDF_ALBUM: {
    accept:
      'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/png, image/gif, image/jpeg',
    name: 'pdf、word、excel、图片、文档格式',
    tip: '文件格式支持pdf、doc、docx、txt格式'
  },
  WORDANDPDF: {
    accept:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/msword',
    name: '“.docx和.pdf”文档格式',
    tip: '文件格式支持docx、pdf格式'
  },
  MEDIAS: {
    accept:
      'image/png, image/jpg, image/jpeg, image/bmp, image/webp, image/tiff, image/svg+xml, image/gif, image/x-icon, image/heic, video/avi, video/x-flv, video/mp4, video/mpeg, video/x-ms-asf, video/x-ms-wmv, video/quicktime, video/x-rmvb, video/rm',
    name: '多媒体文件格式',
    tip: '支持图片视频格式'
  }
});

/** 文件格式默认图 */
export const FORMAT_COVER_IMG = {
  AUDIO:
      'https://file.5rs.me/oss/uploadfe/jpg/20f5fe72867a4d752929ba81c4034bb6.jpg',
  VIDEO:
      'https://file.5rs.me/oss/uploadfe/jpg/d026cd83cf2ff55e3f4b4493a6919712.jpg',
  TEXT:
      'https://file.5rs.me/oss/uploadfe/jpg/a4b2886d0ca57225f7e7e43cbcfe2638.jpg',
  PDF:
      'https://file.5rs.me/oss/uploadfe/jpg/99042a22bd82ab73affe100c5320e690.jpg',
  WORD:
      'https://file.5rs.me/oss/uploadfe/png/b0669624d5c5533510207849fd105d0a.png',
  EXCEL:
      'https://file.5rs.me/oss/uploadfe/png/a17d1f349398c6835965ac9004eddb09.png',
  PPT:
      'https://file.5rs.me/oss/uploadfe/png/ff1468690c5d1fb7d56b500f0d490426.png',
  COMPRESS:
      'https://file.5rs.me/oss/uploadfe/jpg/027f5a04afb7d7740f681962b7ccc8ef.jpg',
  EBOOK:
      'https://file.5rs.me/oss/uploadfe/jpg/4d5117035b0295f144ef8736ce75aa30.jpg',
  IMAGE:
      'https://file.5rs.me/oss/uploadfe/jpg/f4dc070839a3c6ea256d9c2ea375df25.jpg',
  ARTICLE:
      'https://file.5rs.me/oss/uploadfe/jpg/8bdd5c68c7cc6f621dd0bc119c89d63f.jpg',
  OTHER:
      'https://file.5rs.me/oss/uploadfe/jpg/1d0a9710e11408c632f4e372afcd49ba.jpg'

};

/** 第三方图标 */
export const THIRD_APP_ICON = {
  douyin: 'https://oss.5rs.me/oss/uploadfe/png/bfff6144d6b787e1fb554bf3bda420c4.png'
};

export const DEFAULT_BOOK_IMG = 'https://oss.5rs.me/oss/uploadfe/png/28978e149874f2c2bc43b32afbc85697.png';

export const EMPTY_IMAGE = 'https://oss.5rs.me/oss/uploadfe/svg/9f2c741599a0e0759bbcc5bf2251b808.svg';

export const DEFAULT_AVATAR = 'https://oss.5rs.me/oss/uploadfe/png/446e9b106c96f51d5da085468ed5368f.png';

// 文件类型
export const FILE_ICON_MAP = [
  {
    type: FILE_TYPE.WORD,
    icon: 'ic-WORD-icon',
    color: '#006aff'
  },
  {
    type: FILE_TYPE.PDF,
    icon: 'ic-PDF-icon',
    color: '#f1616b'
  },
  {
    type: FILE_TYPE.PPT,
    icon: 'ic-TXT-icon',
    color: '#ee6501'
  },
  {
    type: FILE_TYPE.IMAGE,
    icon: 'ic-picture',
    color: '#ffa100'
  },
  {
    type: FILE_TYPE.ALBUM,
    icon: 'ic-picture',
    color: '#ffa100'
  },
  {
    type: FILE_TYPE.VIDEO,
    icon: 'ic-mp4',
    color: '#ffa100'
  }
];

export const ICONS = {
  'doc': 'ic-WORD-icon',
  'docx': 'ic-WORD-icon',
  'pdf': 'ic-PDF-icon',
  'txt': 'ic-TXT-icon',
  'avi': 'ic-mp4',
  'flv': 'ic-mp4',
  'mp4': 'ic-mp4',
  'mpg': 'ic-mp4',
  'asf': 'ic-mp4',
  'wmv': 'ic-mp4',
  'mov': 'ic-mp4',
  'rmvb': 'ic-mp4',
  'rm': 'ic-mp4'
};

export const ImageExts = ['webp', 'png', 'jpg', 'jpeg', 'bmp', 'tiff', 'svg', 'gif', 'ico', 'heic'];
