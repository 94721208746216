import { Modal as _Modal } from 'antd';

import './index.scss';

const confirm = _Modal.confirm;

/**
 * Modal.confirm
 * @param {import('antd').ModalFuncProps} options
 * @returns {Promise}
 */
function _confirm(options) {
  let { okText, cancelText } = options;
  if(typeof okText === 'string' && okText.length === 2) {
    okText += ' ';
  }
  if(typeof cancelText === 'string' && cancelText.length === 2) {
    cancelText += ' ';
  }
  return confirm({
    centered: true,
    closable: true,
    ...options,
    // https://github.com/ant-design/ant-design/issues/21692
    cancelText: cancelText || '取消 ',
    okText: okText || '确定 ',
    icon: options.icon || <span className="ic ic-xiangqing" style={{ color: options.iconColor }} />
  });
}
/** @type {typeof _Modal & {confirm: typeof _confirm}} */
const Modal = Object.assign(_Modal, {
  confirm: _confirm
});

export default Modal;
