/**
 *  Date    : 2021/12/09
 *  Author  : weiLin
 *  Declare : 默认菜单
 */
import Utils from '@/utils/utils';
import PAGES from '@/constants/page';

// 图标
export const MENUS_ICON = {
  school: 'https://oss.5rs.me/oss/uploadfe/png/c0177de90f290634c26da9dd56a6f935.png',
  activity: 'https://oss.5rs.me/oss/uploadfe/png/b5b52ab07b81c5f01ad59a60d8a52382.png',
  home: 'https://oss.5rs.me/oss/uploadfe/png/18c9c19a645c67fdb9d46e16f76de5e2.png',
  arrow: 'https://oss.5rs.me/oss/uploadfe/png/bc6d2734a87e63ee11f933fb11fdcce4.png',
  banner: 'https://oss.5rs.me/oss/uploadfe/png/dc7f52d8050171b7f1795e82f664d14c.png',
  advertise: 'https://oss.5rs.me/oss/uploadfe/png/7f04482dc3effc1db7b12f7699401b01.png',
  order: 'https://file.5rs.me/oss/uploadfe/png/31f371725adca7717d9922836957c070.png',
  live: 'https://oss.5rs.me/oss/uploadfe/png/6c6bb56faa9d324d751c2cff0bd73be8.png'
};

// 默认菜单
export const PRIVATE_DOMAIN_PERMISSIONS = [
  {
    id: 1,
    invalid: false,
    parentId: 0,
    name: '首页概览',
    shortName: '首页',
    path: PAGES.HOME,
    icon: 'ic-home'
  },
  {
    id: 4,
    invalid: false,
    parentId: 0,
    name: '图书营销',
    shortName: '图书',
    path: PAGES.BOOK,
    icon: 'ic-book'
  },
  {
    id: 5,
    invalid: false,
    parentId: 0,
    name: 'AI专家',
    shortName: 'AI专家',
    path: PAGES.Expert,
    icon: 'ic-expert'
  },
  {
    id: 6,
    invalid: false,
    parentId: 0,
    name: '书船达人',
    shortName: '达人',
    path: PAGES.Channel,
    icon: 'ic-channel'
  },
  {
    id: 7,
    invalid: false,
    parentId: 0,
    name: '数据洞察',
    shortName: '数据',
    path: PAGES.OVERVIEW,
    icon: 'ic-data1'
  }
];

// 格式化配置路由
export default function setPermission(userInfo) {
  let [...routerPath] = PRIVATE_DOMAIN_PERMISSIONS;
  return Utils.unFlatMap(routerPath);
}
