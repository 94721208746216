/**
 * Date    : 2022/1/12
 * Author  : WeiLin
 * Declare : index
 */

import React, { useEffect, useImperativeHandle, useMemo, useState, useRef } from 'react';
import { useCounter, useMount, useUnmount } from 'ahooks';

import { message } from '@/components/AntD';
import { blobToBase64 } from '@/components/FileUpload/FileUtils';
import { FILE_ACCEPT } from '@/constants/common';
import { EventEmitter, EVENT_NAMES } from '@/utils/eventEmitter';
import OBSUploader from '@/components/FileUpload/OBSUpload';
import uuid from '@/utils/uuid';

export default function FilePiker(props) {

  const {
    className, fileRef, children, initialValue, onBefore, onChange, onError, options, type,
    pikerText, pickerText, checkImage, clearCache = false, sizeLimit, local, filterError = true
  } = props;

  // file element
  const filePiker = useRef(null);

  // drop element
  const pikerDrop = useRef(null);

  // event
  const _eventEmitter = useMemo(() => {
    return new EventEmitter();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = useState([]);
  const [uploadTimes, { inc: addUploadTimes }] = useCounter(0);

  useEffect(() => {
    setFiles(initFileValue(initialValue));
  }, [initialValue]);

  // export 方法
  useImperativeHandle(fileRef, () => ({
    filePiker: filePiker.current, // return element
    addFile,
    selectFile,
    uploadFile: files => uploadFile(initFileValue(files)),
    removeFile: fileId => removeFile(fileId)
  }));

  /**state  state部分**/
  const acceptValue = useMemo(() => {
    const { accept } = FILE_ACCEPT()[type] || {};
    return accept || '';
  }, [type]);

  /**effect  effect部分**/
  const mergeOptions = useMemo(() => {
    const FILE_CONFIG = {
      id: 'file',
      name: 'file',
      accept: acceptValue,
      maxfiles: '',
      required: false,
      multiple: false,
      disabled: false
    };
    return Object.assign(FILE_CONFIG, options);
  }, [options, acceptValue]);

  function removeFile(fileId) {
    setFiles((prevState) => {
      const [...newValues] = prevState;
      const updateIndex = newValues.findIndex(item => item.fileId === fileId);
      if(newValues[updateIndex]) {
        newValues.splice(updateIndex, 1);
        onChange(newValues);
      }
      return newValues;
    });
  }
  /**
   * 视图区
   * @returns {*}
   */
  function renderView() {
    const viewChild = children || <span className="cursor" onClick={addFile}>{ pickerText || pikerText }</span>;
    return (
      <div className="file-pool--root">{ viewChild }</div>
    );
  }

  /**
   * 更新 state
   * @param values
   */
  function syncSetFiles(values, finish) {
    setFiles((prevState) => {
      const [...newValues] = prevState;

      const updateIndex = newValues.findIndex(item => item.fileId === values.fileId);

      if(newValues[updateIndex]) {
        const updateItem = newValues[updateIndex] || {};

        newValues[updateIndex] = Object.assign(updateItem, values);

        onChange && onChange(newValues, finish);
      }

      return newValues;
    });
  }

  /**
   * add file
   */
  function addFile() {
    if(clearCache) {
      setFiles([]);
    }
    return new Promise((resolve, reject) => {
      if(!filePiker.current) return;
      filePiker.current.click();
      resolve(true);
    });
  }

  /**
   *  select files
   * @param e
   */
  async function selectFile(e) {
    if(!e.target.files) return;
    let newFiles = [...e.target.files].map(item => {
      item.uuid = uuid();
      return item;
    });
    addUploadTimes();
    const accept = FILE_ACCEPT()[type]?.accept || '';
    if(accept && type && newFiles.find(item => !accept.split(', ').includes(item.type))) {
      onError && onError('请上传' + FILE_ACCEPT()[type].name + '', 'ext');
      return;
    }
    if(options?.maxfiles) {
      if(e.target.files.length + files.length > options.maxfiles) {
        onError && onError('最多只能上传' + options.maxfiles + '个文件', 'count');
        return;
      }
    }

    let errorMsg = '';
    newFiles.map(v => {
      let size = v.size;
      if(sizeLimit && sizeLimit < (size / 1024)) {
        errorMsg = `上传的文件过大，上传的文件大小不能超过${sizeLimit >= 1024 ? Math.floor(sizeLimit / 1024) + 'M' : sizeLimit + 'KB'}!`;
      }
    });
    if(errorMsg) {
      onError ? onError(errorMsg, 'limit') :
        message.error(errorMsg);

      return;
    }
    if(checkImage) {
      const fileReader = new FileReader();
      fileReader.onload = (e => {
        const img = new Image();
        img.onload = () => {
          if(img.width > checkImage.width || img.height > checkImage.height) {
            message.error('图片尺寸超出大小限制');
          } else {
            if(onBefore && !onBefore(newFiles)) {
              return;
            }
            return new Promise((resolve, reject) => {
              // create file
              newFiles = initFileValue(newFiles);
              setFiles(prev => {
                const arr = newFiles.filter(v => !prev.some(e => e.fileId === v.fileId));
                return [...prev, ...arr];
              });
              if(!local) {
                uploadFile(newFiles);
              }
              resolve(newFiles);
            });
          }
        };
        img.src = e.target.result;
      });
      fileReader.readAsDataURL(newFiles[0]);
    } else {
      if(onBefore && !onBefore(newFiles)) {
        return;
      }
      return new Promise((resolve, reject) => {
        // create file
        newFiles = initFileValue(newFiles);
        setFiles(prev => {
          const arr = newFiles.filter(v => !prev.some(e => e.fileId === v.fileId));
          return [...prev, ...arr];
        });
        if(!local) {
          uploadFile(newFiles);
        }
        resolve(newFiles);
      });
    }
  }

  function uploadFile(newFiles) {
    newFiles.forEach(item => {
      // 生成base64、上传图片
      blobToBase64(item.file, (value) => {
        syncSetFiles(Object.assign(item, { fileUrl: value }));
      });
      // 上传文件
      item.upload();
    });
  }

  // 初始化文件对象
  function initFileValue(values = []) {
    let [...files] = values;
    return files.map(file => {
      if(file instanceof Blob) {
        return new OBSUploader({
          file: file,
          config: {
            fail: (error, values) => {
              if(!filterError) {
                syncSetFiles(values);
              }
              console.log(error, '上传失败');
            },
            progress: (values) => {
              syncSetFiles(values);
            },
            success: (values) => {
              syncSetFiles(values, true);
            }
          }
        });
      } else {
        return Object.assign({}, file);
      }
    });
  }

  /**
   * reset file
   */
  function resetFile() {
    setFiles([]);
  }

  useMount(() => {
    // add event
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_ADD`, addFile);
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_UPDATE`, syncSetFiles);
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_SELECT`, selectFile);
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_RESET`, resetFile);
  });

  useUnmount(() => {
    resetFile();
  });
  return (
    <div ref={pikerDrop} className={`FilePiker ${className}`}>
      { renderView() }
      <input key={uploadTimes} ref={filePiker} type="file" {...mergeOptions} style={{ display: 'none' }} onChange={selectFile} />
    </div>
  );
}

FilePiker.defaultProps = {
  className: '',
  action: '',
  options: {}, // maxfiles、required、multiple、disabled
  type: '', // 文件类型
  pikerText: '文件上传',
  onChange: null,
  /** 上传前回调 */
  onBefore: null,
  onError: null,
  allowDrop: false
};
