/**
 *  Date    : 2022/02/10
 *  Author  : weiLin
 *  Declare : 用于上传的File文件对象
 */

import Utils from '@/utils/utils';
import common from '@/api/common';
import { getFileId, getTypeByExt, checkConvertByFormatType, toNaturalFileSize, getExtensionFilename, getFileName } from '@/components/FileUpload/FileUtils';
import { createOBSClient, getBucketName, getFileRoute, getFileUrl } from '@/components/FileUpload/OBSUpload/OBS';

// 需要转码的媒体资源类型
const CONVERT_MEDIA_TYPE = ['AUDIO', 'VIDEO', 'PDF', 'WORD', 'PPT', 'EXCEL'];
// 调用阿里云转码的资源类型，第一次上传不返回url
const ALI_CONVERT_TYPE = ['AUDIO', 'VIDEO'];

class Uploader {

  constructor(props) {
    this.config = Object.assign({}, defaultConfig, props.config);
    this.file = props.file;
    this.fileType = getTypeByExt({ fileName: this.file.name }); // 文件后缀名转类型，如 AUDIO、VIDEO 等
    this.fileId = getFileId(this.file);
    this.fileName = getFileName(this.file.name);
    this.size = toNaturalFileSize(this.file.size);
    this.fileSize = this.file.size;
    this.fileUrl = '';
    this.fileExist = false; // 文件是否已存在，秒传，无需转码
    this.isConvert = false; // 文件是否已转码成功
    this.client = null;
    this.duration = null;
    this.percent = 0;
    this.rate = 0;
    this.unmounted = false;
    this.uploadData = {}; // 上传参数
    return {
      file: this.file,
      fileId: this.fileId,
      fileName: this.fileName,
      fileType: this.fileType,
      fileSize: this.fileSize,
      size: this.size,
      fileUrl: this.fileUrl,
      duration: this.duration,
      percent: this.percent,
      rate: this.rate,
      isFileExist: () => this.fileExist,
      isFileConverted: () => this.isConvert,
      isAliConvert: () => this.isAliConvert,
      config: Object.assign({}, this.config),
      upload: this.upload,
      getUploadData: this.getUploadData,
      setUnmounted: this.setUnmounted,
      checkFileExist: this.checkFileExist
    };
  }

  /**
   * 文件转码
   * @param convert
   * @returns {Promise<void>}
   */
  async formatConvert(convert) {
    let { hasConvert, duration } = ALI_CONVERT_TYPE.includes(this.fileType) ? await checkConvertByFormatType({ file: this.file }) : { hasConvert: false };
    this.config.convert = convert && CONVERT_MEDIA_TYPE.includes(this.fileType); // 是否需要转码
    this.isAliConvert = this.config.convert && hasConvert; // 是否是阿里云转码(目前只针对音视频)
    duration && (this.duration = duration);
  }

  /**
   * 初始化 client
   * @returns {Promise<null>}
   */
  async init() {
    await this.formatConvert(this.config.convert);
    if(window.ObsClient) {
      this.client = createOBSClient(this.isAliConvert);
      return this.client;
    } else {
      this.uploadFail('OSS not exist');
    }
  }

  /**
   * 更新上传配置
   */
  setUploadData() {
    const { setUploadData } = this.config;
    if(setUploadData) {
      this.uploadData = setUploadData(...arguments);
    }
  }

  /**
   * 获取上传配置
   * @returns {{}}
   */
  getUploadData() {
    return this.uploadData;
  }

  /**
   * 开始上传
   */
  upload() {
    if(!this.unmounted && this.file) {
      this.checkFileExist();
    }
  }

  /**
   * 检查上传文件
   */
  checkFileExist=() => {
    common.checkTick({ fileId: this.fileId, beyond: this.config.beyond ? 1 : 0 })
      .then((response = {}) => {
        // 秒传
        if(response.finish) {
          this.fileExist = true;
          this.isConvert = response.isConvert;
          this.percent = 100;
          this.uploadSuccess(Object.assign(response, { fileUrl: response.url, percent: this.percent }));
        } else {
          this.uploadFile();
        }
      })
      .catch(err => {
        this.uploadFail(err, () => {
          //refallback
          this.fileId = getFileId(this.file, true);
          this.checkFileExist();
        });
      });
  }

  /**
   * 上传文件
   * @returns {Promise<void>}
   */
  async uploadFile() {
    const client = await this.init();
    if(client) {
      client.uploadFile({
        Bucket: getBucketName(this.isAliConvert),
        Key: getFileRoute(this.file),
        SourceFile: this.file,
        ProgressCallback: this.progressOnChange
      })
        .then(res => {
          this.setUploadData('SAVE', res);
          const url = getFileUrl(this.file);
          this.saveUrl(url);
        })
        .catch(err => {
          this.uploadFail(err, () => {
            //refallback
            this.fileId = getFileId(this.file, true);
            this.checkFileExist();
          });
        });
    }
  }

  /**
   *
   * @param transferredAmount
   * @param totalAmount
   * @param totalSeconds
   */
  progressOnChange=(transferredAmount = 0, totalAmount = 1, totalSeconds = 1) => {

    const { progress } = this.config;

    // 获取上传平均速率（KB/S）
    const uploadRate = transferredAmount / totalSeconds / 1024;

    // 获取上传进度百分比
    const uploadPercent = transferredAmount * 100.0 / totalAmount;

    this.percent = uploadPercent;

    this.rate = uploadRate;

    !this.unmounted && progress && progress({ fileId: this.fileId, percent: uploadPercent, rate: uploadRate });
  }

  /**
   * 保存上传url
   * @param url
   */
  saveUrl(url = '') {
    if(url) {
      let params = {
        fileId: this.fileId,
        isConvert: Boolean(ALI_CONVERT_TYPE.includes(this.fileType) ? this.isAliConvert : this.config.convert),
        name: `${getFileName(this.file.name)}`.slice(0, 199), //后端文件名字数超过200会报错，所以把文件名称裁切一下
        size: this.file.size,
        type: getExtensionFilename(this.file.name),
        url,
        beyond: this.config.beyond ? 1 : 0,
        duration: this.duration
      };
      common.updateFinish(Utils.searchAssign(params))
        .then((response = {}) => {
          // 阿里云转码的资源类型不返回文件url
          if(this.isAliConvert) {
            this.uploadSuccess('');
          } else {
            this.uploadSuccess({ fileId: this.fileId, fileUrl: url });
          }
        })
        .catch(err => {
          this.uploadFail(err);
        });
    }
  }

  /**
   * 上传成功
   * @param values
   */
  uploadSuccess(values = {}) {
    const { success } = this.config;

    if(this.unmounted) return;

    success && success(values);
  }

  /**
   * 上传失败
   * @param err
   */
  uploadFail(err = '', refallback) {
    let error = err;
    if(this.unmounted) return;

    console.error(error);

    if(error?.message && error.message.indexOf('远程oss文件不存在') > -1) {
      refallback && refallback();
      return;
    }

    if(!error || typeof error !== 'string') {
      error = '服务器内部错误，请稍后再试！';
    }

    const { fail } = this.config;
    fail && fail(error);
  }

  /**
   * unmounted
   */
  setUnmounted() {
    this.unmounted = true;
  }

}

const defaultConfig = {
  convert: false, // 是否需要转码
  beyond: false, // 是否处于系统外，值为true表示没有cookie用户信息
  progress: () => {},
  success: () => {},
  fail: () => {},
  setUploadData: () => {} // 设置自定义数据
};

export default Uploader;
