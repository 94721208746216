import axiosServices from './request';

const AiReview = axiosServices('/aireview/v1.0');

export default {
  /** 获取结果分类统计 */
  wrongWordClassify: AiReview('post', 'aiReviewAssistant/wrongWordClassify'),
  /** 分页获取错词列表 */
  wrongWordList: AiReview('post', 'aiReviewAssistant/wrongWordList'),
  wrongWordListWithFeedback: AiReview('post', 'aiReviewAssistant/wrongWordListWithFeedback'),
  /** 批量忽略错误 */
  batchIgnore: AiReview('post', 'aiReviewAssistant/batchIgnore'),
  /** PDF文件,查重文件链接 */
  getResultUrl: AiReview('post', 'aiReviewAssistant/getResultUrl'),
  /** 分页获取查重报告 */
  getDuplicateList: AiReview('post', 'aiReviewAssistant/getDuplicateList'),
  /** 查重（！！内部人员标记用） */
  getDuplicateListWithFeedback: AiReview('post', 'aiReviewAssistant/getDuplicateListWithFeedback'),
  /** 新增批注 */
  insertFusionItem: AiReview('post', 'aiReviewAssistant/insertFusionItem'),
  /** 新增AI助理 */
  getReviewAIText: AiReview('post', 'aiReviewAssistant/getText'),
  /** 导出校对报告 */
  exportReportList: AiReview('get', 'aiReviewAssistant/exportReportList'),
  /** 添加反馈（！！内部人员标记用） */
  addItemFeedback: AiReview('get', 'aiReviewAssistant/addItemFeedback'),
  /** 获取知识查找结果 */
  getAiAssistantList: AiReview('get', 'aiReviewAssistant/getAiAssistantList'),
  /** 修改批注 */
  updateFusionItem: AiReview('post', 'aiReviewAssistant/updateFusionItem'),
  /** 删除知识查找 */
  softAssistantDeleteById: AiReview('get', 'aiReviewAssistant/softAssistantDeleteById'),
  /** 换一个知识查找 */
  reText: AiReview('get', 'aiReviewAssistant/reText'),
  /** 查询word仅包含高频批注稿链接 */
  getAnnotationWord4HighFrequencyError: AiReview('get', 'aiReviewAssistant/getAnnotationWord4HighFrequencyError'),
  /** 查询审读意见 */
  reviserDocumentMediaRelateExportReport: AiReview('get', 'reviserDocumentMediaRelate/exportReport'),
  /** 查找答案 */
  answerFindByUrl: AiReview('post', 'answer/findByUrl'),
  /** 查找答案历史 */
  answerFindByBizId: AiReview('get', 'answer/findByBizId'),
  /** 删除查找答案记录 */
  answerDelete: AiReview('get', 'answer/delete'),
  /** 是否展示“知识查证” */
  enableVerification: AiReview('get', 'aiReviewAssistant/enableVerification'),
  /** 检查pdf文档类目 */
  checkFileType: AiReview('post', 'answer/checkFileType'),
  /** 检查用户是否有上传权限 */
  getFileUploadAuth: AiReview('get', 'aiReviewAssistant/getFileUploadAuth')
};
