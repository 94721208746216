import Utils from '@/utils/utils';

const VOYAGE_ADVISER = '/adviser';

const MAIN_PAGES = {
  /** 登录 */
  LOGIN: '',
  /** 首页 */
  HOME: VOYAGE_ADVISER + '/home',
  /** 图书营销 */
  BOOK: VOYAGE_ADVISER + '/book',
  /** AI专家 */
  EXPERT: VOYAGE_ADVISER + '/expert'

};

export const PAGES = {
  ...MAIN_PAGES,
  Expert: '/adviser/expert',
  EXPERT_IMAGE: '/adviser/expert/image',
  Channel: '/adviser/channel',
  OVERVIEW: '/adviser/overview',

  // ---- 图书营销 ----
  /** 营销详情 */
  BOOK_DETIAL: MAIN_PAGES.BOOK + '/detail',

  // ---- AI专家 ----
  /** AI专家iframe */
  EXPERT_IFRAME: MAIN_PAGES.EXPERT + '/iframe'
};

// 获取带params路由
export function getPageUrl(page, params) {
  let url = page;
  Object.keys(params)
    .forEach(key => {
      if(url.indexOf(`:${key}`) > -1) {
        url = url.replace(`:${key}`, params[key]);
        delete params[key];
      }
    });
  return `${url}${Utils.formatQuery2QueryStr(params)}`;
}

export default PAGES;

