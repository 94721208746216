/**
 * Date    : 2021/11/27
 * Author  : WeiLin
 * Declare : index
 */
import { Button as _Button } from 'antd';

export default function Button(props) {
  const { className, ...resetProps } = props;
  return (
    <_Button className={`original-btn ${props.className}`} {...resetProps}>
      { props.children }
    </_Button>
  );
}
